<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <b-table
      ref="refListTable"
      :items="dataList"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('futures.emptyText')"
      :sort-desc.sync="isSortDirDesc"
      striped
    >

      <template #thead-top>
        <b-tr>
          <b-th colspan="12">
            {{ totalCount }} {{ totalCount > 1 ? $t('futures.posicao.pluralPosicao'): $t('futures.posicao.singularPosicao') }}
          </b-th>
        </b-tr>
      </template>

      <template #cell(paridade)="data">
        <div class="text-nowrap">
          <b-avatar
            size="sm"
            :src="getIcon(data.item.moedaTrade)"
          />
          <span class="mr-1">
            {{ data.item.moedaTrade }}<span class="font-weight-light">/{{ data.item.paridadeTrade }}</span>
          </span>
        </div>
      </template>

      <template #cell(ordemPosition)="data">
        <div class="text-nowrap">
          {{ data.value }}
          <b-badge variant="light-warning">
            {{ data.item.alavancagem }}x
          </b-badge>
        </div>
      </template>

      <template #cell(precoCompra)="data">
        {{ toFixed(data.value, 6) }}
      </template>

      <template #cell(alvo)="data">
        <template v-if="data.value">
          {{ toFixed(data.value, 6) }} <br>
          <b-badge variant="light-success">
            <template v-if="data.item.ordemPosition=='Long'">
              {{ getPercentDiff(data.item.precoCompra, data.item.alvo) }}%
            </template>
            <template v-else>
              {{ getPercentDiff(data.item.alvo, data.item.precoCompra) }}%
            </template>
          </b-badge>
        </template>
        <template v-else>
          -
        </template>
      </template>

      <template #cell(statusWs)="data">
        <template v-if="tickers[data.item.moedaParidade]!=null">
          <div class="text-nowrap">
            <span
              v-if="data.item.ordemPosition=='Long'"
              :class="parseFloat(tickers[data.item.moedaParidade].price) >= parseFloat(data.item.precoCompra)? 'text-success' : 'text-danger'"
            >
              {{ toFixed(((parseFloat(tickers[data.item.moedaParidade].price) - parseFloat(data.item.precoCompra)) * data.item.quantidade), 2) }}
              <small>USDT</small> <br>
              <b-badge :variant="parseFloat(tickers[data.item.moedaParidade].price) >= parseFloat(data.item.precoCompra)? 'light-success' : 'light-danger'">

                ({{ toFixed(((( parseFloat(tickers[data.item.moedaParidade].price) - parseFloat(data.item.precoCompra) ) * data.item.quantidade)
                  / (data.item.quantidade * parseFloat(data.item.precoCompra) * (1/data.item.alavancagem))) * 100, 1) }}%)

              </b-badge>

            </span>
            <span
              v-if="data.item.ordemPosition=='Short'"
              :class="parseFloat(data.item.precoCompra) >= parseFloat(tickers[data.item.moedaParidade].price)? 'text-success' : 'text-danger'"
            >
              {{ toFixed( -1 * ((parseFloat(tickers[data.item.moedaParidade].price) - parseFloat(data.item.precoCompra)) * data.item.quantidade), 2) }}
              <small>USDT</small> <br>
              <b-badge :variant="parseFloat(data.item.precoCompra) >= parseFloat(tickers[data.item.moedaParidade].price)? 'light-success' : 'light-danger'">
                ({{ toFixed(-1 * ((( parseFloat(tickers[data.item.moedaParidade].price) - parseFloat(data.item.precoCompra) ) * data.item.quantidade)
                  / (data.item.quantidade * parseFloat(data.item.precoCompra) * (1/data.item.alavancagem))) * 100, 1) }}%)
              </b-badge>
            </span>

          </div>
        </template>
        <template v-else>
          <div class="text-center">
            <b-spinner small />
          </div>
        </template>
      </template>

      <template #cell(precoWs)="data">
        <template v-if="tickers[data.item.moedaParidade]!=null">
          <span
            v-if="data.item.ordemPosition=='Long'"
            :class="parseFloat(data.item.precoCompra) <= parseFloat(tickers[data.item.moedaParidade].price)? 'text-success' : 'text-danger'"
          >
            {{ tickers[data.item.moedaParidade].price }}
            <br>
            <b-badge :variant="parseFloat(data.item.precoCompra) <= parseFloat(tickers[data.item.moedaParidade].price)? 'light-success' : 'light-danger'">
              {{ getPercentDiff(data.item.precoCompra, tickers[data.item.moedaParidade].price ) }}%
            </b-badge>
          </span>
          <span
            v-else
            :class="parseFloat(data.item.precoCompra) >= parseFloat(tickers[data.item.moedaParidade].price)? 'text-success' : 'text-danger'"
          >
            {{ tickers[data.item.moedaParidade].price }}
            <br>
            <b-badge :variant="parseFloat(data.item.precoCompra) >= parseFloat(tickers[data.item.moedaParidade].price)? 'light-success' : 'light-danger'">
              {{ getPercentDiff(data.item.precoCompra, tickers[data.item.moedaParidade].price ) }}%
            </b-badge>
          </span>
        </template>
        <template v-else>
          <div class="text-center">
            <b-spinner small />
          </div>
        </template>
      </template>

      <template #cell(stopLoss)="data">
        <template v-if="data.value">
          {{ data.value }} <br>
          <b-badge
            v-if="data.item.ordemPosition=='Long'"
            variant="light-danger"
          >
            {{ getPercentDiff(data.item.precoCompra, data.item.stopLoss) }}%
          </b-badge>
          <b-badge
            v-else
            variant="light-danger"
          >
            {{ getPercentDiff(data.item.stopLoss, data.item.precoCompra) }}%
          </b-badge>
        </template>
        <template v-else>
          -
        </template>
      </template>

      <template #cell(total)="data">
        <template v-if="data.value && data.item.alvo">
          {{ toFixed(data.value, 6) }}
        </template>
        <template v-else>
          -
        </template>
      </template>

      <template #cell(dataCriacao)="data">
        {{ formatDateTimeSemAnoBR(data.value) }}
      </template>

      <template #cell(agente)="data">
        {{ data.value }} <br>
        <b-badge variant="light-primary">
          #{{ data.item.sinalId }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(acao)="data">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            @click.stop="confirmarEncerrarAgora(data.item.id)"
          >
            <feather-icon icon="TargetIcon" />
            <span class="align-middle ml-50">{{ $t('futures.posicao.encerrarAgora') }}</span>
          </b-dropdown-item>

        </b-dropdown>

      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('futures.textMuted', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCount"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>

</template>

<script>

import {
  BCard, BTable, BAvatar, BPagination, BRow, BCol, BSpinner, BBadge, BDropdown, BDropdownItem, BTr, BTh,
} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateTimeSemAnoBR, percentDiferenca } from '@/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import posicaoStoreModule from './posicaoStoreModule'

const POSICAO_FUT_STORE_MODULE_NAME = 'posicao-fut'

export default {

  components: {
    BCard,
    BTable,
    BAvatar,
    BPagination,
    BRow,
    BCol,
    BSpinner,
    BBadge,
    BDropdown,
    BDropdownItem,
    BTr,
    BTh,
  },

  mixins: [utilsMixin],

  data() {
    return {
      polling: null,
      formatDateTimeSemAnoBR,
      percentDiferenca,
      perPage: 10,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'id,desc',
      isSortDirDesc: true,
      dataList: [],
    }
  },

  computed: {
    tableColumns() {
      return [
        { key: 'paridade', label: this.$i18n.t('futures.posicao.tableHeader.paridade') },
        { key: 'ordemPosition', label: this.$i18n.t('futures.posicao.tableHeader.ordemPosition') },
        { key: 'quantidade', label: this.$i18n.t('futures.posicao.tableHeader.quantidade') },
        { key: 'precoCompra', label: this.$i18n.t('futures.posicao.tableHeader.precoCompra') },
        { key: 'stopLoss', label: this.$i18n.t('futures.posicao.tableHeader.stopLoss') },
        { key: 'precoWs', label: this.$i18n.t('futures.posicao.tableHeader.precoWs') },
        { key: 'alvo', label: this.$i18n.t('futures.posicao.tableHeader.alvo') },
        { key: 'statusWs', label: this.$i18n.t('futures.posicao.tableHeader.statusWs') },
        { key: 'total', label: this.$i18n.t('futures.posicao.tableHeader.total') },
        { key: 'dataCriacao', label: this.$i18n.t('futures.posicao.tableHeader.dataCriacao') },
        { key: 'agente', label: this.$i18n.t('futures.posicao.tableHeader.trader') },
        { key: 'acao', label: this.$i18n.t('futures.posicao.tableHeader.acao') },
      ]
    },
    tickers() {
      return this.$store.state.binanceFut.tickers
    },

    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },
  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(POSICAO_FUT_STORE_MODULE_NAME)) store.registerModule(POSICAO_FUT_STORE_MODULE_NAME, posicaoStoreModule)
    this.pollfetchList()
  },

  beforeDestroy() {
    clearInterval(this.polling)
    if (store.hasModule(POSICAO_FUT_STORE_MODULE_NAME)) store.unregisterModule(POSICAO_FUT_STORE_MODULE_NAME)
  },

  methods: {

    fetchList() {
      store
        .dispatch('posicao-fut/getPosicoesAbertas', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          status: 'aberta',
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
          if (content && content[0] && content[0].bloqueiaControle) {
            this.tableColumns = this.tableColumns.filter(column => column.key !== 'acao')
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    // vender agora
    confirmarEncerrarAgora(id) {
      this.$swal({
        title: 'Tem certeza que deseja encerrar a posição agora?',
        text: 'Sua posição será encerrada pelo preço de mercado',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode encerrar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.encerrarAgora(id)
        }
      })
    },

    encerrarAgora(id) {
      this.$store.dispatch('posicao-fut/encerrarPosicao', id)
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Encerrando posição!',
            text: 'Sua posicao está sendo encerrada',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

    pollfetchList() {
      this.fetchList()
      const self = this
      this.polling = setInterval(() => {
        self.fetchList()
      }, 5000)
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
